import mixpanel from "mixpanel-browser";

mixpanel.init(process.env.MIXPANEL_TOKEN);
window.mixpanel = mixpanel;

document.addEventListener("DOMContentLoaded", function () {
  var userContext = getUserContext();
  if (window.mixpanel && userContext) {
    mixpanel.identify(userContext.id);
    mixpanel.people.set();
    mixpanel.register({
      category: userContext.category,
      companyName: userContext.companyName,
      status: userContext.status,
      trial: userContext.trial,
    });
  }
  if (userContext === null || userContext.category === "designer") {
    // For visitors and designers.
    mixpanel.track("Page Viewed");
  }
});
